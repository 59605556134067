import { getFormDefinition, createFormDataFromItem, createItemFromFormData } from './form';
import { entityToStore } from '@/utils/store';
import baseCrudMixin from '@/mixins/crud';
export default {
  mixins: [baseCrudMixin],
  data() {
    return {
      definition: this.createDefinition(),
      entity: 'Role',
      returnTo: 'Roles'
    };
  },
  computed: {
    permissions() {
      return this.$store.state.permission.items;
    },
    entityStore() {
      return entityToStore(this.entity);
    }
  },
  watch: {
    permissions() {
      this.definition = { ...this.createDefinition() };
    }
  },
  created() {
    this.$store.dispatch('permission/getItems');
  },
  methods: {
    createDefinition() {
      return getFormDefinition(this);
    },
    createFormDataFromItem,
    createItemFromFormData
  }
};
