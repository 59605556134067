<template>
  <crud-editor
    ref="editor"
    :is-edit="true"
    :definition="definition"
    :fetch-by-id="fetchById"
    :disabled="hasNoEditPermission"
    :save="saveData"
    :return-to="returnTo"
    :item-type="entity"
    :page-loading="loading"
  />
</template>

<script>
import CrudEditor from '@/components/crud/Editor';
import formMixin from './form-mixin';
import { hasEditPermissionForCode } from '@/utils/permission';

export default {
  components: {
    CrudEditor
  },
  mixins: [formMixin],
  computed: {
    hasNoEditPermission() {
      return !hasEditPermissionForCode(this.entity, this.$store.getters.permissions);
    },
    loading() {
      return this.$store.getters[this.entityStore + '/isLoading'] || this.$store.getters['user/isLoading'];
    }
  },
  methods: {
    fetchById(id) {
      return this.$store
        .dispatch(this.entityStore + '/getItem', { selector: { id } })
        .then(item => this.createFormDataFromItem(this, item));
    },
    saveData(form) {
      const role = this.createItemFromFormData(this, form);
      return this.$store.dispatch(this.entityStore + '/persistItem', role).then(() => {
        // Update permissions to update sidebar
        this.$store.dispatch('getUserInfo');
      });
    }
  }
};
</script>
